import React from 'react';
import {
  Main,
  Part,
  Aside,
  Basic,
  MarginProvider,
  Layout as PageLayout,
  Appearance as PageAppearance,
} from 'Shared/PageLayout';
import MainMenuItem from 'SiteLayout/MainMenu/MainMenuItem.type';
import BreadcrumbsViewModel from 'Shared/Breadcrumbs/BreadcrumbsViewModel.type';
import Breadcrumbs from 'Shared/Breadcrumbs';
import Viewport, { ForExtensive } from 'Shared/Viewport';
import { AppearanceType } from '../appearance';
import * as style from 'Shared/Style';

export { Appearance } from 'Shared/PageLayout';

type SharedPropType = {
  appearance?: AppearanceType<PageAppearance>;
  breadcrumbs?: BreadcrumbsViewModel[];
};

type StrictPropType = {
  layout?: PageLayout;
};

// A `StrictPage` or `Page` component should be the root element to your page
export const StrictPage: React.StatelessComponent<SharedPropType & StrictPropType> = ({
  breadcrumbs,
  ...restProps
}) => (
  <Main>
    {breadcrumbs && (
      <ForExtensive>
        <Basic appearance={[PageAppearance.Normal, PageAppearance.Gap]}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Basic>
      </ForExtensive>
    )}
    <Basic {...restProps} />
  </Main>
);

type PropType = SharedPropType & {
  subnavigation?: MainMenuItem[];
  prioritizedSubContent?: React.ReactNode;
  unprioritizedSubContent?: React.ReactNode;
};

const PageExtensive: React.StatelessComponent<PropType> = ({
  subnavigation,
  children,
  prioritizedSubContent,
  unprioritizedSubContent,
  ...passProps
}) => {
  const asideNode = (prioritizedSubContent || subnavigation || unprioritizedSubContent) && (
    <MarginProvider margin={style.Margin.Small}>
      <Aside>
        {prioritizedSubContent}
        {subnavigation && (
          <Part>
            {/* TODO: Add submenu here */}
            {/* <SubMenu items={subnavigation} /> */}
          </Part>
        )}
        {unprioritizedSubContent}
      </Aside>
    </MarginProvider>
  );

  return asideNode ? (
    <StrictPage layout={PageLayout.OneToFour} {...passProps}>
      <Basic>{children}</Basic>
      {asideNode}
    </StrictPage>
  ) : (
    <StrictPage {...passProps}>{children}</StrictPage>
  );
};

const PageCompact: React.StatelessComponent<PropType> = ({
  appearance,
  subnavigation,
  breadcrumbs,
  children,
  prioritizedSubContent,
  unprioritizedSubContent,
  ...passProps
}) => (
  <StrictPage appearance={appearance} {...passProps}>
    {prioritizedSubContent}
    {children}
    {unprioritizedSubContent}
  </StrictPage>
);

const Page: React.StatelessComponent<PropType> = props => (
  <Viewport>{(isCompact: boolean) => (isCompact ? <PageCompact {...props} /> : <PageExtensive {...props} />)}</Viewport>
);

export default Page;
