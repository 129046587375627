/**
 * @ComponentFor StartPageViewModel
 */
import React from 'react';
import { EpiProperty, epiPropertyValue, Breakpoint } from '@avensia/scope';
import PropType from './StartPageViewModel.type';
import Page from 'Shared/Page';
import { Main, Appearance } from 'Shared/PageLayout';
import { styled } from '@glitz/react';
import { media } from '@glitz/core';
import * as style from 'Shared/Style';
import Image from 'Shared/Image';
import HexagonButton from 'Shared/Button/Hexagon';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import FadeIntoView from 'Shared/Animations/FadeIntoView';

const CenterContent = styled.div({
  width: '100%',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    textAlign: 'center',
  }),
});

const MainWrapper = styled(Main, {
  width: '100%',
  margin: {
    top: '29px',
    left: 'auto',
    right: 'auto',
  },
  padding: {
    left: '28px',
    right: '28px',
  },
  ...media(style.mediaMinQueries[Breakpoint.Medium], {
    margin: {
      top: '99px',
    },
    padding: {
      left: '4vw',
      right: '4vw',
    },
  }),
  ...media(style.mediaMinQueries[Breakpoint.Large], {
    padding: {
      left: '8vw',
      right: '8vw',
    },
  }),
});

const BkgPattern = styled.div({
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    backgroundImage:
      'url(' +
      require('../Shared/Image/PatternLeft.png') +
      '), url(' +
      require('../Shared/Image/PatternRight.png') +
      ') ',
    backgroundPosition: 'top left, top right',
    backgroundRepeat: 'repeat-y',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    zIndex: -1,
  }),
});

const ImageContainer = styled.div({
  position: 'absolute',
  top: 0,
  width: '100%',
  overflow: 'hidden',
  zIndex: -1,
  height: 'calc(800px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const BkgImage = styled(Image, {
  width: '100%',
  objectFit: 'cover',
  height: 'calc(800px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '560px',
  }),
});

const ImageHeaderContentWrapper = styled.div({
  color: style.WHITE,
  textShadow: '0 0 10px rgba(0,0,0,0.5)',
  margin: {
    bottom: '80px',
    x: 'auto',
  },
  maxWidth: style.maxContentWidth - 480,
  padding: {
    top: '18px',
    bottom: '0',
  },
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    padding: {
      top: '80px',
    },
  }),
  height: 'calc(690px - 50vw)',
  ...media(style.mediaMinQueries[Breakpoint.Small], {
    height: '450px',
  }),
});

const ImageHeaderFontSize = styled.div({
  fontSize: '1em',
  ...media(style.mediaMinQueries[Breakpoint.Tiny], {
    fontSize: '1.2em',
  }),
});

const ButtonWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: {
    top: '2em',
    bottom: '2em',
    right: '-2em',
  },
});

export default (props: PropType) => {
  return (
    <>
      <BkgPattern />
      <ImageContainer>
        <BkgImage src={epiPropertyValue(props.content.imageUrl)} />
      </ImageContainer>
      <Page>
        <MainWrapper appearance={[Appearance.BackgroundImage, Appearance.Center]}>
          <CenterContent id="mainContent">
            <ImageHeaderContentWrapper>
              <FadeIntoView>
                <ImageHeaderFontSize>
                  <EpiProperty for={props.content.headerContent} />
                </ImageHeaderFontSize>
                {epiPropertyValue(props.content.headerButtonLink) && (
                  <ButtonWrapper>
                    <HexagonButton
                      appearance={ButtonAppearance.Highlight}
                      to={epiPropertyValue(props.content.headerButtonLink)}
                    >
                      <span> {epiPropertyValue(props.content.headerButtonText)}</span>
                    </HexagonButton>
                    {epiPropertyValue(props.content.headerButton2Link) && (
                      <HexagonButton
                        appearance={ButtonAppearance.Highlight}
                        to={epiPropertyValue(props.content.headerButton2Link)}
                      >
                        <span> {epiPropertyValue(props.content.headerButton2Text)}</span>
                      </HexagonButton>
                    )}
                    {epiPropertyValue(props.content.headerButton3Link) && (
                      <HexagonButton
                        appearance={ButtonAppearance.Highlight}
                        to={epiPropertyValue(props.content.headerButton3Link)}
                      >
                        <span> {epiPropertyValue(props.content.headerButton3Text)}</span>
                      </HexagonButton>
                    )}
                  </ButtonWrapper>
                )}
              </FadeIntoView>
            </ImageHeaderContentWrapper>

            <EpiProperty for={props.content.mainContent} />
          </CenterContent>
        </MainWrapper>
      </Page>
    </>
  );
};
